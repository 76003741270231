class AnnouncementBar extends HTMLElement {
  constructor() {
    super();

    // Fetching the users country to limit visible messages
    this.fetchUserCountry();

  }

  fetchUserCountry() {
    // Fetch the current country handle from a JSON availabile through the Geolocation app to see where the user is visiting from
    fetch('/browsing_context_suggestions.json')
    .then((res) => res.json())
    .then((data) => {
      // Select all message elements
      this.querySelectorAll('.text-animation-slider__item').forEach((element) => {

        // Set variables based on location
        var global = element.dataset.countries == 'global' ? true : false;
        var supported = element.dataset.countries == 'eu' && window.geoLocation.EU.includes(data.detected_values.country.handle);
        var unsupported = element.dataset.countries == 'unsupported' && !window.geoLocation.EU.includes(data.detected_values.country.handle) && !window.geoLocation.US.includes(data.detected_values.country.handle) && !window.geoLocation.JP.includes(data.detected_values.country.handle);
        var US = element.dataset.countries == 'us' && window.geoLocation.US.includes(data.detected_values.country.handle) && !supported;
        var JP = element.dataset.countries == 'jp' && window.geoLocation.JP.includes(data.detected_values.country.handle) && !supported;

        if (global || supported || unsupported || US || JP ) {
          // keep
        } else {
          // Remove elements that dont meet the location requirements
          element.remove();
        }


      });
      // After filtering out all elements that should not be looped through, initialize animation
      this.initAnimation();
    });
  }

  initAnimation() {

    this.animateContainer = this.querySelector('.text-animation-slider__container');
    if (this.animateContainer) {

      this.animateSlider = this.querySelector('.text-animation-slider__slider');
      this.animatedItems = this.animateSlider.querySelectorAll('.text-animation-slider__item');

      // After the array is altered in 'fetchUserCountry', we need to give the first element an active class
      if (!this.animatedItems[0]) return
      this.animatedItems[0].classList.add('active', 'animated');

      // Creating empty timeouts
      this.animateTextTimeout = null;
      this.animateTextTimeoutResize = null;

      // Is the timeout running first time?
      this.firstLoad = true;

      // Get duration of the sliding messages based on a value from the customizer
      this.animateDuration = this.animateSlider.getAttribute('data-delay');

      // If only single element, abort
      if (this.animatedItems.length === 1) return;

      // Run the animation
      this.animate();
    }

  }

  animate() {

    // Run each time duration is reached
    this.animateTextTimeout = setTimeout(function () {

      // Select whatever element is active for next run
      var activeElement = this.animateSlider.querySelector('.active');

      // Default classes
      const noAnimationOnFirstElementClass = 'animated';
      const animateInClass = 'animate-in';
      const animateOutClass = 'animate-out';
      const activeClass = 'active';

      // Get the next element, if there is nothing after, select the first for a loop effect.
      var nextElement = activeElement.nextElementSibling ? activeElement.nextElementSibling : this.animateSlider.firstElementChild;

      // Set classes based on what is activly showing
      activeElement.classList.add(animateOutClass);
      activeElement.classList.remove(activeClass, noAnimationOnFirstElementClass, animateInClass);
      nextElement.classList.add(activeClass, animateInClass);

      // Not first run anymore
      this.firstLoad = false;

      // Retrigger the animate function after duration
      this.animate();

    }.bind(this), this.animateDuration );

  }
}

customElements.define('announcement-bar', AnnouncementBar);